export default function Custom404() {
  return (
    <>
      <div className="quiz-heading">404 - Page Not Found </div>
      <p className="quiz-paragraph">
        Please check the URL or try again another time.
      </p>
    </>
  )
}
